/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/* tslint:disable */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Footer from 'Component/Footer';
import InstallPrompt from 'Component/InstallPrompt';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsPage from 'Route/CmsPage';
import { CmsPageContainerProps } from 'Route/CmsPage/CmsPage.type';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';
import { Query } from '@tilework/opus';
import {
    CategoryListQueryOptions,
    categoryList
} from '../../query/CategoryList.types';
import CategoryListQuery from '../../query/CategoryList.query'
import ProductListQuery from 'Query/ProductList.query';
import CmsBlock from 'Component/CmsBlock';
import BreadcrumbsDispatcher from 'Store/Breadcrumbs/Breadcrumbs.dispatcher';
import { executePost } from "Util/Request/Request";
import { prepareQuery } from "Util/Query";
import { getIndexedProducts } from 'Util/Product';
import Link from 'Component/Link';
import {
    HomePageContainerMapDispatchProps,
    HomePageContainerMapStateProps,
    HomePageContainerProps,
    HomePageContainerPropsKeys,
} from './HomePage.type';
import ProductListPage from 'Component/ProductListPage';
import ProductCard from 'Component/ProductCard';
import SliderWidget from 'Component/SliderWidget'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

import './Hompage.style.scss';
import '../../component/MainBanner/MainBanner.style.scss';

import MainBanner from '../../component/MainBanner';
import MainCategories from '../../component/MainCategories';

function SampleNextArrow(props) {
    const { className, style, onClick, click } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red", right: "30px" }}
            onClick={() => click(onClick)}
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                height="1em" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>

        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick, click } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", left: "30px", zIndex: '1' }}
            onClick={() => click(onClick)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
        </div>
    );
}


/** @namespace Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): HomePageContainerMapStateProps => ({
    identifier: state.ConfigReducer.cms_home_page,
});

/** @namespace Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): HomePageContainerMapDispatchProps => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    toggleBreadcrumbs: (isActive) => {
        BreadcrumbsDispatcher.update([], dispatch);
        dispatch(toggleBreadcrumbs(isActive));
    },

});

/** @namespace Route/HomePage/Container */
export class HomePageContainer extends PureComponent<HomePageContainerProps> {
    state = {
        // sets the default state
        categoryData: [],
        newArrivals: [],
    };


    constructor(props) {
        super(props);

        const {
            toggleBreadcrumbs,
        } = this.props;

        toggleBreadcrumbs(false);
    }


    componentDidMount(): void {
        window.isPrefetchValueUsed = false


        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: DEFAULT_STATE_NAME,
            isHiddenOnMobile: false,
        });

        // prepareRequest(
        //     options: CategoryListQueryOptions,
        // ): Query<'categoryList', categoryList, false> {
        //     return CategoryListQuery.getQuery();
        // }

        const inputs = {
            url_key: {
                eq: "homepage"
            }
        }


        try {
            executePost(
                prepareQuery([CategoryListQuery.getQuery(inputs)])
            ).then((result) => {

                this.setState({ categoryData: result?.categoryList })

            }).catch((err) => {


            });




        } catch (error) {
            console.log(error);

        }

        const newToDate = this.getRequestDate();

        const options = {
            args: {
                filter: {
                    // categoryUrlPath,
                    newToDate: { news_to_date: { gteq: "2023-07-10", lteq: "2023-07-11" } },
                },
                currentPage: 1,
                //  pageSize,
            },
        };

        const query = [ProductListQuery.getQuery(options)];

        executePost(
            prepareQuery(query)
        ).then((result) => {
            // console.log(result, "products");
            if (result?.products?.items) {
                this.setState({ newArrivals: getIndexedProducts(result?.products?.items) })
            }


        }).catch((err) => {
            console.log(err);
        });

    }

    getRequestDate(): string {
        const today = new Date();
        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 15);

        const year = twoDaysAgo.getFullYear();
        const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1.
        const day = String(twoDaysAgo.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    containerProps(): Pick<CmsPageContainerProps, HomePageContainerPropsKeys> {
        const {
            changeHeaderState,
            currentUrl,
            match,
            identifier,
        } = this.props;

        return {
            changeHeaderState,
            currentUrl,
            match,
            pageIdentifiers: identifier,
        };
    }

    renderCategoryList(): void {

        return (
            <div block="HomepageCategoriesBox-wrapper">

                <div>
                    <h3 block="HomepageCategoriesBox-title head-title">{__('Categories')} {this.state?.categoryData[0]?.name}</h3>
                </div>
                <div block="HomepageCategoriesBox" >
                    {
                        this.state?.categoryData[0]?.children.map((item, index): void => {
                            return (
                                <div block="HomepageCategoriesInfo">
                                    <Link to={item.url}>
                                        <figure class="HomepageCategoriesBox-Figure">
                                            <div class="Image">
                                                <img src={item.image} alt="" loading="lazy" class="Image-Image" />
                                            </div>
                                            <figcaption class="HomepageCategoriesBox-Figcaption">
                                                <p style="background: transparent;"> {item.name}
                                                </p>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        )
    }



    renderProductsList(items): void {
        var currentSlide = 4
        // return (
        //     <ProductListPage
        //         items={getIndexedProducts(items)}
        //     />
        // )

        const addCounter = (callback): void => {
            if (currentSlide == 16) {
                currentSlide = 4;
                return
            }
            currentSlide = currentSlide + 1
            callback()
        };


        const decreaseCounter = (calllback): void => {
            // if(currentSlide == 0){
            //     currentSlide = 16;
            //     return
            // }
            // currentSlide = currentSlide - 1
            calllback()
        }



        const settings = {
            initialSlide: 0,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            accessibility: true,
            swipeToSlide: true,
            swipe: true,
            nextArrow: <SampleNextArrow click={addCounter} />,
            prevArrow: <SamplePrevArrow click={decreaseCounter} />,
            onInit: () => {
                currentSlide = 4
            },
            // appendDots: dots => {
            //     const array = []

            //     const row = Math.ceil(dots.length / 4)

            //     Array.from({ length: row }).forEach((_, index) => {
            //         array.push(4 * (index + 1))
            //     })




            //     return (
            //         <div>
            //             <ul>
            //                 {array.map((item, index) => {

            //                     return (
            //                         <>
            //                             {currentSlide}{item}
            //                             <li className={(currentSlide < (item + 4) && currentSlide >= item) ? 'slick-active' : null} key={index}>
            //                                 <button>
            //                                 </button>
            //                             </li>
            //                         </>
            //                     );

            //                 })}
            //             </ul>
            //         </div>
            //     )
            // },
            // customPaging: index => {
            //     console.log(index === this.state.currentSlide, "index === this.state.currentSlide");

            //     return (
            //         <button style={index === this.state.currentSlide ? {
            //             backgroundColor: 'rgba(255, 255, 255, 0.8)',
            //             outline: '0'
            //         } : null}>
            //             {index + 1}
            //         </button>
            //     )
            // }
        };
        console.log(this.state, "State")
        console.log(this.props, "props")
        return (
           
            
            <div>
                <div>
                    <Slider
                        // ref={c => (this.slider = c)}
                        {...settings}
                    >

                        {
                            items.map((item, index) => {
                                return (
                                    <>
                                        <ProductCard
                                            key={index}
                                            product={item}
                                        />
                                    </>
                                )
                            })
                        }
                    </Slider>

                </div>
            </div >
        )

    }


    renderCategoryChildrenProduct(): void {
        // console.log(this.state?.categoryData[0]?.children, "categoryData");

        return this.state?.categoryData[0]?.children.map((item, index) => {
            return (
                <div key={index}>
                    <div>
                        <h3 block="HomepageCategoriesBox-title head-title">{item.name}</h3>
                    </div>
                    {this.renderProductsList(getIndexedProducts(item.products?.items))}
                </div>
            )
        })

    }

    renderNewlyArrivals(): void {

        return (
            <div>
                <div>
                    <h3 block="HomepageCategoriesBox-title head-title">{__('Recien Llegados')}</h3>
                </div>
                {this.renderProductsList(this.state.newArrivals)}
            </div>
        )
    }

    renderTestimonials(): void {
        return (
            <>
                <div block="testimonials-heading">
                    <h3>{__('Testimonios')}</h3>
                </div>
                <div block="testimonials-wrapper">
                    <div block="testimonial">
                        <div block="testimonial-description">
                            <p>{__('Totalmente encantada con el servicio. Muy amables, muy atentos y el envío perfecto. No tardaron ni dos días desde que lo compré. Sin duda un acierto confiar en ellos. Gracias')}
                            </p>
                        </div>

                        <div block="testimonial-heading">
                            <h3>{__(' - YAKELYN ZUMAETA CASTRO | JUEZA LIMA NORTE')}</h3>
                        </div>

                    </div>
                    <div block="testimonial">
                        <div block="testimonial-description">
                            <p>{__('La atención fue muy personalizada, gracias a la cual se han podido entender las necesidades de mi despacho. Más que recomendado')}

                            </p>
                        </div>

                        <div block="testimonial-heading">
                            <h3> {__('- MILAGROS SÁNCHEZ DÍAZ | JUEZA CIVIL DE AREQUIPA')}</h3>
                        </div>

                    </div>
                    <div block="testimonial">
                        <div block="testimonial-description">
                            <p>{__('Yo no puedo más que recomendar. De todos los servicios que he probado, este el el mejor con diferencia')}
                            </p>
                        </div>

                        <div block="testimonial-heading">
                            <h3> {__('- MARCOS RIVERO MEDINA | ABOGADO CIUDAD DE TRUJILLO')}</h3>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    renderPreFooterContentCms(): void {
        return (
            <div block="pre-footer-content-wrapper">
                <CmsBlock identifier={"pre-footer-content"} />;
            </div>
        )
    }

    render(): ReactElement {
        console.log("home-page")
        return (
            <div block="HomePage">
                {/* <InstallPrompt /> */}
                {/* <CmsPage {...this.containerProps()} /> */}
                <div block="HomePage-HeroBanner">
                    <SliderWidget sliderId="3" />
                </div>
                {/* <MainBanner /> */}
                <MainCategories />
                {/* {this.renderCategoryList()}
                {this.renderNewlyArrivals()}
                {this.renderCategoryChildrenProduct()} */}
                {/* {this.renderTestimonials()} */}
                {/* {this.renderPreFooterContentCms()} */}

                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
