/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactElement } from 'Type/Common.type';
import {
    MainCategoriesContainerMapStateProps,
    MainCategoriesContainerMapDispatchProps,
    MainCategoriesContainerProps,
    MainCategoriesContainerPropsKeys
} from './MainCategories.type'

import MainCategories from "./MainCategories.component"

import HomePageDispatcher from "../../store/HomePage/HomePage.dispatcher"

/** @namespace Component/MainCategories/Container/mapStateToProps */
export const mapStateToProps = (state: any): MainCategoriesContainerMapStateProps => ({
    isLoading: state.HomePageReducer.isLoading,
    config: state.HomePageReducer.config,
    categories: state.HomePageReducer.categories,
});

/** @namespace Component/MainCategories/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): MainCategoriesContainerMapDispatchProps => ({
    getConfig: () => HomePageDispatcher.getConfig(dispatch)
});

/** @namespace Component/MainCategories/Container */
export class MainCategoriesContainer extends PureComponent<MainCategoriesContainerProps> {

    constructor(props: any) {
        super(props);

        const {
            getConfig,
            config
        } = props

        if (Object.keys(config).length == 0) {
            getConfig()
        }
    }

    containerFunctions = {
    };

    containerProps(): Pick<MainCategoriesContainerProps, MainCategoriesContainerPropsKeys> {

        const { isLoading, config, categories } = this.props;
        
        return {
            isLoading,
            config,
            categories
        };
    }

    render(): ReactElement {

        return (
            <MainCategories
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainCategoriesContainer);
