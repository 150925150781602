/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import { ReactElement } from 'Type/Common.type';
import CmsBlock from 'Component/CmsBlock';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import Link from 'Component/Link';
import { noopFn } from 'Util/Common';

import { MainCategoriesComponentProps } from './MainCategories.type';
import ProductSlider from "../ProductSlider"
import './MainCategories.style';

/** @namespace Component/CartCoupon/Component */
export class MainCategoriesComponent extends PureComponent<MainCategoriesComponentProps> {

    static defaultProps: Partial<any> = {
        onItemClick: noopFn,
    };


    handleViewAll(category: any) {
        history.push({ pathname: appendWithStoreCode(category.url_key) });
    }

    renderCategoryTopRow(category: any): ReactElement {

        const {
            onItemClick
        } = this.props

        return <div block="category_description">
            <span block="slider_title">{category.name}</span>
            <Link
                block="view_all_product"
                to={category.url}
                elem="Link"
                id={category.id}
                onClick={onItemClick}
            >
                {__('View All')}
            </Link>
        </div>
    }

    renderCategory(category: any): ReactElement {

        let isBlockShow = false
        let identifier = null
        const { config } = this.props

        if (Object.keys(config).length > 0 && config?.homepage_after_category_block_display.length > 0) {
            config.homepage_after_category_block_display.forEach((item) => {
                if (item.category == category.url_key) {
                    isBlockShow = true
                    identifier = item.block
                }
            })
        }

        if (isBlockShow) {
            return <div block="slider">
                {this.renderCategoryTopRow(category)}

                <ProductSlider
                    items={category.products.items}
                    slideShowCount={6}
                    mediumlg={4}
                    smalllg={2}
                />
                <CmsBlock identifier={identifier} />
            </div>
        }

        if (category.home_left_block_details != null) {
            return <div block="product-cms-block slider">
                <div block="slide-item-1">
                    {this.renderCategoryTopRow(category)}

                    <ProductSlider
                        items={category.products.items}
                        slideShowCount={3}
                        medium={2}
                        small={1}
                    />
                </div>
                <div block="slide-item-2">
                    <CmsBlock identifier={category.home_left_block_details.identifier} />
                </div>
            </div>
        } else {
            return <div block="slider">
                {this.renderCategoryTopRow(category)}
                <ProductSlider
                    items={category.products.items}
                    slideShowCount={6}
                    mediumlg={4}
                    smalllg={2}
                />
            </div>
        }
    }



    renderCategories(): ReactElement {
        const { categories } = this.props

        if (categories.length > 0) {

            return (
                <div>
                    {categories.map((category) => {
                        return <div>{this.renderCategory(category)}</div>
                    })}
                </div>
            );
        }

        return null

    }

    render(): ReactElement {
        const { isLoading } = this.props;

        return (
            <div
                block="MainCategories container"
            >
                <Loader isLoading={isLoading} />
                {this.renderCategories()}
            </div>
        );
    }
}

export default MainCategoriesComponent;
