/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/* tslint:disable */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import { Argument, Field, Query } from '@tilework/opus';
// import { CmsBlock } from 'Query/CmsBlock.type';
import {
    Breadcrumb,
    CmsBlock
} from 'Query/Category.type';


import {
    CategoryListQueryOptions,
    categoryList
} from './CategoryList.types';


import ProductListQuery from "Query/ProductList.query"
import {
    CategoryInterface,
    ComplexTextValue,
    OptimizedProductImage,
    Money,
    PriceRange,
    ProductPrice,
    ProductDiscount,
    GQLCurrencyEnum,
    AttributeWithValue,
    ProductStockItem,
    GQLProductStockStatus,
    UrlRewrite,
    MediaGalleryEntry
} from "Type/ProductList"


/**
 * Category Query
 * @class CategoryQuery
 * @namespace Query/Category/Query
 */



export class CategoryListQuery {

    count = 0;

    getQuery(options): Query<'categoryList', categoryList> {

        return new Query<'categoryList', categoryList>('categoryList')
            .addArgument(
                "filters",
                "CategoryFilterInput",
                options
            )
            .addFieldList(this._getDefaultFields())
    }

    _getDefaultFields(): Array<
        Field<'id', number>
        | Field<'url', string>
        | Field<'name', string>
        | Field<'image', string>
        | Field<'url_key', string>
        | Field<'url_path', string>
        | Field<'is_active', boolean>
        | Field<'meta_title', string>
        | Field<'description', string>
        | Field<'canonical_url', string>
        | Field<'product_count', number>
        | Field<'meta_keywords', string>
        | Field<'default_sort_by', string>
        | Field<'meta_description', string>
        | Field<'landing_page', number>
        | Field<'display_mode', string>
        | Field<'is_anchor', boolean>
        | Field<'cms_block', CmsBlock>
        | Field<'breadcrumbs', Breadcrumb, true>
    > {


        return [
            new Field<'id', number>('id'),
            new Field<'url', string>('url'),
            new Field<'name', string>('name'),
            new Field<'image', string>('image'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'is_active', boolean>('is_active'),
            new Field<'meta_title', string>('meta_title'),
            new Field<'description', string>('description'),
            new Field<'canonical_url', string>('canonical_url'),
            new Field<'product_count', number>('product_count'),
            new Field<'meta_keywords', string>('meta_keywords'),
            new Field<'default_sort_by', string>('default_sort_by'),
            new Field<'meta_description', string>('meta_description'),
            new Field<'landing_page', number>('landing_page'),
            new Field<'display_mode', string>('display_mode'),
            new Field<'is_anchor', boolean>('is_anchor'),
            this._ProductField(),
            this._categorychildrenField1()

        ];
    }
    _categorychildrenField3(): Field<'children', Object> {
        return new Field<'children', Object>('children')
            .addFieldList(this._categorychildrenFields3());
    }

    _categorychildrenFields3(): Array<
        Field<'name', string>
        | Field<'path', string>
        | Field<'uid', string>
        | Field<'url', string>
        | Field<'url_key', string>
        | Field<'url_path', string>
        | Field<'image', string>
        | Field<'url', string>
    > {
        return [
            new Field<'name', string>('name'),
            new Field<'path', string>('path'),
            new Field<'uid', string>('uid'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'image', string>('image'),
            new Field<'url', String>('url'),
            this._getMenuLeftBlockField(),
        ];
    }

    _categorychildrenField2(): Field<'children', Object> {
        return new Field<'children', Object>('children')
            .addFieldList(this._categorychildrenFields2());
    }

    _categorychildrenFields2(): Array<
        Field<'name', string>
        | Field<'path', string>
        | Field<'uid', string>
        | Field<'url', string>
        | Field<'url_key', string>
        | Field<'url_path', string>
        | Field<'image', string>
        | Field<'url', string>
    > {
        return [
            new Field<'name', string>('name'),
            new Field<'path', string>('path'),
            new Field<'uid', string>('uid'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'image', string>('image'),
            new Field<'url', String>('url'),
            this._getMenuLeftBlockField(),
            this._categorychildrenField3(),
        ];
    }

    _categorychildrenField1(): Field<'children', Object> {
        return new Field<'children', Object>('children')
            .addFieldList(this._categorychildrenFields1());
    }

    _categorychildrenFields1(): Array<
        Field<'name', string>
        | Field<'path', string>
        | Field<'uid', string>
        | Field<'url', string>
        | Field<'url_key', string>
        | Field<'url_path', string>
        | Field<'image', string>
        | Field<'url', string>
    > {
        return [
            new Field<'name', string>('name'),
            new Field<'path', string>('path'),
            new Field<'uid', string>('uid'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'image', string>('image'),
            new Field<'url', String>('url'),
            this._getMenuLeftBlockField(),
            this._categorychildrenField2(),
            this._ProductField()
        ];
    }


    _getMenuLeftBlockField(): Field<'home_left_block_details', CmsBlock> {
        return new Field<'home_left_block_details', CmsBlock>('home_left_block_details')
            .addFieldList(this._getCmsBlockField());
    }

    _getCmsBlockField(): Array<
        Field<'title', string>
        | Field<'content', string>
        | Field<'identifier', string>
        | Field<'disabled', boolean>
    > {
        return [
            new Field<'title', string>('title'),
            new Field<'content', string>('content'),
            new Field<'identifier', string>('identifier'),
            new Field<'disabled', boolean>('disabled'),
        ];
    }


    _ProductField(): Field<'products', Object> {
        return new Field<'products', Object>('products')
            .addFieldList(this._ProductFields());
    }


    _ProductFields(): Array<
        Field<'total_count', number>

    > {
        return [
            new Field<'total_count', string>('total_count'),
            this._ProductItemField()
        ];
    }


    _ProductItemField(): Field<'items', Object> {
        return new Field<'items', Object>('items')
            .addFieldList(this._ProductItemFields());
    }

    _ProductItemFields(): Array<
        Field<'name', string>
        | Field<'license_key', string>
        | Field<'meta_title', string>
        | Field<'qty', number>
        | Field<'quantity', number>
        | Field<'review_count', number>
        | Field<'url_key', string>
        | Field<'url', string>
        | Field<'uid', string>
        | Field<'canonical_url', string>
        | Field<'meta_title', string>
        | Field<'meta_keyword', string>
        | Field<'meta_description', string>
        | Field<'categories', CategoryInterface>
        | Field<'description', ComplexTextValue>
        | Field<'image', OptimizedProductImage>
        | Field<'price_range', PriceRange>
        | Field<'small_image', OptimizedProductImage>
        | Field<'thumbnail', OptimizedProductImage>
        | Field<'s_attributes', AttributeWithValue>
        | Field<'salable_qty', number>
        | Field<'short_description', ComplexTextValue>
        | Field<'sku', string>
        | Field<'type_id', string>
        | Field<'special_from_date', string>
        | Field<'special_to_date', string>
        | Field<'stock_item', ProductStockItem>
        | Field<'stock_status', GQLProductStockStatus>
        | Field<'url_rewrites', UrlRewrite>
        | Field<'id', number>
        | Field<'ConfigurableProduct', ConfigurableProductFragment>
        | Field<'BundleProduct', BundleProductFragment>
        | Field<'BundleProduct', MediaGalleryEntry>
        | Field<'rating_summary', number>
    // | Field<'id', GroupedProductItem[]>
    > {
        return [
            new Field<'name', string>('name'),
            new Field<'license_key', string>('license_key'),
            new Field<'meta_title', string>('meta_title'),
            new Field<'qty', number>('qty'),
            new Field<'quantity', number>('quantity'),
            new Field<'review_count', number>('review_count'),
            new Field<'url_key', string>('url_key'),
            new Field<'url', string>('url'),
            new Field<'uid', string>('uid'),
            new Field<'canonical_url', string>('canonical_url'),
            new Field<'meta_title', string>('meta_title'),
            new Field<'meta_keyword', string>('meta_keyword'),
            new Field<'meta_description', string>('meta_description'),
            new Field<'salable_qty', number>('salable_qty'),
            new Field<'sku', string>('sku'),
            new Field<'special_to_date', string>('special_to_date'),
            new Field<'stock_status', GQLProductStockStatus>('stock_status'),
            new Field<'id', number>('id'),
            new Field<'type_id', string>('type_id'),
            new Field<'special_from_date', string>('special_from_date'),
            this._getCategoriesField(),
            this._getDescriptionField(),
            this._getProductImageField(),
            this._getPriceRangeField(),
            this._getProductSmallField(),
            this._getProductThumbnailField(),
            ProductListQuery._getReviewsField(),
            ProductListQuery._getAttributesField(false, false),
            ProductListQuery._getShortDescriptionField(),
            ProductListQuery._getRatingSummaryField(),
            ProductListQuery._getStockItemField(),
            ProductListQuery._getUrlRewritesFields(),
            ProductListQuery._getMediaGalleryField(),
            ProductListQuery._getConfigurableProductFragment(),
            // ProductListQuery._getBundleProductFragment(),
            // ProductListQuery._getGroupedProductItems(),

        ];
    }

    _getBreadcrumbFields(): Array<
        Field<'category_id', number>
        | Field<'category_name', string>
        | Field<'category_level', number>
        | Field<'category_url', string>
        | Field<'category_is_active', boolean>
    > {
        return [
            new Field<'category_id', number>('category_id'),
            new Field<'category_name', string>('category_name'),
            new Field<'category_level', number>('category_level'),
            new Field<'category_url', string>('category_url'),
            new Field<'category_is_active', boolean>('category_is_active'),
        ];
    }

    _getBreadcrumbsField(): Field<'breadcrumbs', Breadcrumb, true> {
        return new Field<'breadcrumbs', Breadcrumb, true>('breadcrumbs', true)
            .addFieldList(this._getBreadcrumbFields());
    }

    _getCategoryFields(): Array<
        Field<'id', string>
        | Field<'name', string>
        | Field<'url', string>
        | Field<'breadcrumbs', Breadcrumb, true>
    > {
        return [
            new Field<'id', string>('id'),
            new Field<'name', string>('name'),
            new Field<'url', string>('url'),
            this._getBreadcrumbsField(),
        ];
    }

    _getCategoriesField(): Field<'categories', CategoryInterface, true> {
        return new Field<'categories', CategoryInterface, true>('categories', true)
            .addFieldList(this._getCategoryFields());
    }

    _getDescriptionFields(): Field<'html', string>[] {
        return [
            new Field<'html', string>('html'),
        ];
    }

    _getDescriptionField(): Field<'description', ComplexTextValue> {
        return new Field<'description', ComplexTextValue>('description')
            .addFieldList(this._getDescriptionFields());
    }

    _getProductThumbnailFields(): Array<
        Field<'path', string>
        | Field<'url', string>
    > {
        return [
            new Field<'path', string>('path'),
            new Field<'url', string>('url'),
        ];
    }

    _getProductImageField(): Field<'image', OptimizedProductImage> {
        return new Field<'image', OptimizedProductImage>('image')
            .addFieldList(this._getProductThumbnailFields());
    }

    _getProductSmallFields(): Array<
        Field<'path', string>
        | Field<'url', string>
    > {
        return this._getProductThumbnailFields();
    }

    _getProductSmallField(): Field<'small_image', OptimizedProductImage> {
        return new Field<'small_image', OptimizedProductImage>('small_image')
            .addFieldList(this._getProductSmallFields());
    }

    _getProductThumbnailField(): Field<'thumbnail', OptimizedProductImage> {
        return new Field<'thumbnail', OptimizedProductImage>('thumbnail')
            .addFieldList(this._getProductThumbnailFields());
    }

    _getDiscountField(): Field<'discount', ProductDiscount> {
        return new Field<'discount', ProductDiscount>('discount')
            .addField(new Field<'amount_off', number>('amount_off'))
            .addField(new Field<'percent_off', number>('percent_off'));
    }

    _getFinalPriceField(): Field<'final_price', Money> {
        return new Field<'final_price', Required<Money>>('final_price')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getFinalPriceExclTaxField(): Field<'final_price_excl_tax', Money> {
        return new Field<'final_price_excl_tax', Money>('final_price_excl_tax')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getRegularPriceField(): Field<'regular_price', Money> {
        return new Field<'regular_price', Money>('regular_price')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getRegularPriceExclTaxField(): Field<'regular_price_excl_tax', Money> {
        return new Field<'regular_price_excl_tax', Money>('regular_price_excl_tax')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getDefaultFinalPriceExclTaxField(): Field<'default_final_price_excl_tax', Money> {
        return new Field<'default_final_price_excl_tax', Money>('default_final_price_excl_tax')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getDefaultPriceField(): Field<'default_price', Money> {
        return new Field<'default_price', Money>('default_price')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }


    _getDefaultFinalPriceField(): Field<'default_final_price', Money> {
        return new Field<'default_final_price', Money>('default_final_price')
            .addField(new Field<'currency', GQLCurrencyEnum>('currency'))
            .addField(new Field<'value', number>('value'));
    }

    _getMinimalPriceFields(): Array<
        Field<'discount', ProductDiscount>
        | Field<'final_price', Money>
        | Field<'final_price_excl_tax', Money>
        | Field<'regular_price', Money>
        | Field<'regular_price_excl_tax', Money>
        | Field<'default_price', Money>
        | Field<'default_final_price', Money>
        | Field<'default_final_price_excl_tax', Money>
    > {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getFinalPriceExclTaxField(),
            this._getRegularPriceField(),
            this._getRegularPriceExclTaxField(),
            this._getDefaultPriceField(),
            this._getDefaultFinalPriceField(),
            this._getDefaultFinalPriceExclTaxField(),
        ];
    }

    _getMinimalPriceField(): Field<'minimum_price', ProductPrice> {
        return new Field<'minimum_price', ProductPrice>('minimum_price')
            .addFieldList(this._getMinimalPriceFields());
    }



    _getMaximalPriceField(): Field<'maximum_price', ProductPrice> {
        return new Field<'maximum_price', ProductPrice>('maximum_price')
            .addFieldList(this._getMinimalPriceFields());
    }


    _getPriceRangeFields(): Array<
        Field<'minimum_price', ProductPrice>
        | Field<'maximum_price', ProductPrice>
    > {
        // Using an array as potentially would want to add maximum price
        return [
            this._getMinimalPriceField(),
            this._getMaximalPriceField(),
        ];
    }

    _getPriceRangeField(): Field<'price_range', PriceRange> {
        return new Field<'price_range', PriceRange>('price_range')
            .addFieldList(this._getPriceRangeFields());
    }


}


export default new CategoryListQuery();
