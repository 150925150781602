/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';
import ProductSlider from "./ProductSlider.component"


/** @namespace Component/ProductSlider/Container */
export class ProductSliderContainer extends PureComponent {
    
    containerProps() {
        const { items, slideShowCount } = this.props;

        return {
            items,
            slideShowCount
        };
    }

    render(): ReactElement {

        return (
            <ProductSlider
              { ...this.containerProps() }
            />
        );
    }
}

export default (ProductSliderContainer);

