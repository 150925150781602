/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Dispatch } from 'redux';

import { fetchQuery } from 'Util/Request/Query';
import HomePageQuery from '../../query/HomePage.query'
import {
    CategoryQueryOptions
} from '../../query/HomePage.type'
import {
    isLoadingAction,
    categoriesAction,
    configAction
} from "./HomePage.action"

import {
    HomePageCategoryBlock
} from "./HomePage.type"


/**
 * HomePage Dispatcher
 * @class HomePageDispatcher
 * @namespace Store/HomePage/Dispatcher
 */
export class HomePageDispatcher {

    async getConfig(dispatch: Dispatch): Promise<void> {
        try {
            dispatch(isLoadingAction(true))
            const { storeConfig:{
                homepage_after_category_block_display,
                homepage_category
            } } = await fetchQuery(HomePageQuery.getStoreConfigQuery())

            let blockAndCategories:HomePageCategoryBlock[] = []
            if(homepage_after_category_block_display != null){
                blockAndCategories = Object.values(JSON.parse(homepage_after_category_block_display))
            }

            dispatch(configAction({
                homepage_category: homepage_category,
                homepage_after_category_block_display: blockAndCategories
            }))

            if(homepage_category != null){
                this.getCategories(dispatch, homepage_category)
            }else{
                dispatch(isLoadingAction(false))
            }

        } catch (error) {
            dispatch(isLoadingAction(false))
            console.log('getConfig catch error', error);
        }
    }

    async getCategories(dispatch: Dispatch, urlKey: String): Promise<void> {
        try {

            const options: CategoryQueryOptions={
                args:{
                    currentPage: 1, 
                    pageSize: 1,
                    filters: {
                        urlKey: urlKey
                    }
                }
            }

            const { categoryList } = await fetchQuery(HomePageQuery.getCategory(options))
            
            dispatch(categoriesAction(categoryList[0].children))
            dispatch(isLoadingAction(false))

        } catch (error) {
            dispatch(isLoadingAction(false))
            console.log('getCategories catch error', error);
        }
    }
}

export default new HomePageDispatcher();
