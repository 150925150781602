/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import Html from 'Component/Html';
import { ReactElement } from 'Type/Common.type';

import { CmsBlockComponentProps } from '@scandipwa/scandipwa/src/component/CmsBlock/CmsBlock.type';

import './CmsBlock.style';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Component/CmsBlock/Component
 */
export class CmsBlockComponent extends PureComponent<CmsBlockComponentProps> {
    static defaultProps: Partial<CmsBlockComponentProps> = {
        cmsBlock: {},
        blockType: '',
    };


    renderPlaceholder(): ReactElement {
        const {
            children,
        } = this.props;

        if (children && (!Array.isArray(children) || children.length)) {
            return children;
        }

        return null;
    }

    render(): ReactElement {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled,
            },
            blockType,
        } = this.props;


        function decodeHtml(html: any) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }


        if (!content || disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        // if (identifier === 'footer_social_icons' || this.props.deCode) {

        //     const iconStyles = {
        //         display: 'flex',
        //         margin: '20px 0',
        //     };
        //     console.log(identifier, "icon")
        //     return (
        //         <div
        //             block="CmsBlock"
        //             elem="Wrapper"
        //             mods={{ type: blockType }}
        //             style={iconStyles}
        //         >
        //             <div dangerouslySetInnerHTML={{ __html: decodeHtml(content) }} />
        //         </div>
        //     )
        // }
        // else {
            
            return (
                <div
                    block="CmsBlock"
                    elem="Wrapper"
                    mods={{ type: blockType }}
                >
                    {/* <Html content={content} /> */}
                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(content) }} />
                </div>
            );
        // }
    }
}

export default CmsBlockComponent;
