/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 import {
    HomePageActionType,
    HomePageConfig
} from './HomePage.type';

/**
 * @namespace Store/HomePage/Action/isLoadingAction
 */
 export const isLoadingAction = (isLoading: Boolean) => ({
    type: HomePageActionType.IS_LOADING,
    isLoading
});

/**
 * @namespace Store/HomePage/Action/categoriesAction
 */
export const categoriesAction = (categories: any[]) => ({
    type: HomePageActionType.GET_CATEGORIES,
    categories
});

/**
 * @namespace Store/HomePage/Action/configAction
 */
 export const configAction = (config: HomePageConfig) => ({
    type: HomePageActionType.GET_CONFIG,
    config
});