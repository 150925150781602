/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
/* stylelint-disable */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';
import { ReactElement } from 'Type/Common.type';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getIndexedProducts } from 'Util/Product';
import ProductCard from 'Component/ProductCard';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red", right: "30px" }}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                height="1em" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>

        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", left: "30px", zIndex: '1' }}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512">
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
        </div>
    );
}

/** @namespace Component/ProductSlider/Component */
export class ProductSliderComponent extends PureComponent {

    render(): ReactElement {
        const { slideShowCount, medium, mediumlg, small } = this.props

        var currentSlide = 4

        const addCounter = (callback): void => {
            if (currentSlide == 16) {
                currentSlide = 4;
                return
            }

            if (slideShowCount == 3) {
                currentSlide = currentSlide + 3
            }


            currentSlide = currentSlide + 6
            callback()
        };

        const decreaseCounter = (calllback): void => {
            calllback()
        }

        const slideCountToScroll = () => {
            let slidesToShow, slidesToScroll
            if (slideShowCount === 6) {
                return (
                    slidesToShow = 4,
                    slidesToScroll = 4
                )
            } else {
                return (
                    slidesToShow = 2,
                    slidesToScroll = 2
                )
            }
        }

        const slideCountToScrollinMobile = () => {
            let slidesToShow, slidesToScroll
            if (slideShowCount === 6) {
                return (
                    slidesToShow = 2,
                    slidesToScroll = 2
                )
            } else {
                return (
                    slidesToShow = 1,
                    slidesToScroll = 1
                )
            }
        }
        const isMobile = window.innerWidth <= 810;

        const defaultCount = 6

        const settings = {
            initialSlide: 0,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slideShowCount,
            slidesToScroll: slideShowCount,
            // arrows: true,
            // accessibility: true,
            // swipeToSlide: false,
            swipe: isMobile ? true : false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            // onInit: () => {
            //     currentSlide = 4
            // },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: slideCountToScroll(),
                        slidesToScroll: slideCountToScroll(),

                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: slideCountToScrollinMobile(),
                        slidesToScroll: slideCountToScrollinMobile(),

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        const { items } = this.props;

    
        return (
            <div>
                <Slider
                    {...settings}
                >
                    {
                        getIndexedProducts(items).map((item, index) => {
                            return (
                                <>
                                    <ProductCard
                                        
                                        key={index}
                                        product={item}
                                        hideWishlistButton={false}
                                        hideCompareButton={true}
                                    // hideAddToCartButton={true}
                                    />
                                </>
                            )
                        })
                    }
                </Slider>
            </div>
        );
    }
}

export default ProductSliderComponent;
