/* eslint-disable */
/* jsx-a11y/click-events-have-key-events */
/* simple-import-sort/imports */
// @ts-nocheck
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

import { Argument, Field, Query } from '@tilework/opus';

import {
    StoreConfigQueryType,
    CategoryQueryOptions,
    FilterArgumentMap,
    CategoryAttributeFilterOptions,
    ArgumentsMap,
    CategoryQueryOutput,
    CategoryTree,
    CmsBlock,
    CategoryProducts,
    ProductInterface
} from "./HomePage.type"

import CategoryListQuery from "./CategoryList.query"

/**
 * HomePage Query
 * @class HomePageQuery
 * @namespace Query/HomePage/Query */

export class HomePageQuery {

    options: Partial<CategoryQueryOptions> = {};

    /**
     * get StoreConfig query
     * @return {Field} StoreConfig query
     * @memberof HomePageQuery
     */
    getStoreConfigQuery(): Query<'storeConfig', StoreConfigQueryType, true> {
        return new Query<'storeConfig', StoreConfigQueryType, true>('storeConfig', true)
            .addFieldList(this._getStoreConfigFields());
    }

    _getStoreConfigFields(): Array<
        Field<'homepage_category', string>
        | Field<'homepage_after_category_block_display', string>
    > {
        return [
            new Field<'homepage_category', string>('homepage_category'),
            new Field<'homepage_after_category_block_display', string>('homepage_after_category_block_display')
        ];
    }

    /**
     * get Category query
     * @return {Field} Category query
     * @memberof HomePageQuery
     */
    getCategory(options: Partial<CategoryQueryOptions> = {}): Query<'categoryList', any> {

        this.options = options;

        const categoryList = new Query<'categoryList', any>('categoryList')
            // .addField(this._getChildrenFields());
            .addFieldList(this._getCategoryListFields());

        this._getCategoryArguments().forEach((arg) => categoryList.addArgument(...arg));

        return categoryList;
    }

    _getCategoryListFields(){
        return[
            new Field<'id', number>('id'),
            new Field<'url', string>('url'),
            new Field<'name', string>('name'),
            new Field<'image', string>('image'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'is_active', boolean>('is_active'),
            new Field<'meta_title', string>('meta_title'),
            new Field<'description', string>('description'),
            new Field<'canonical_url', string>('canonical_url'),
            new Field<'product_count', number>('product_count'),
            new Field<'meta_keywords', string>('meta_keywords'),
            new Field<'default_sort_by', string>('default_sort_by'),
            new Field<'meta_description', string>('meta_description'),
            new Field<'landing_page', number>('landing_page'),
            new Field<'display_mode', string>('display_mode'),
            new Field<'is_anchor', boolean>('is_anchor'),
            this._getCmsBlockField(),
            this._getHomeLeftCmsBlockField(),
            this._getChildrenFields()
        ]
    }

    _getChildrenFields(): Field<'children', CategoryTree> {
        return new Field<'children', CategoryTree>('children')
            .addFieldList(this._getDefaultFields());
    }

    _getDefaultFields(): Array<
        Field<'id', number>
        | Field<'url', string>
        | Field<'name', string>
        | Field<'image', string>
        | Field<'url_key', string>
        | Field<'url_path', string>
        | Field<'is_active', boolean>
        | Field<'meta_title', string>
        | Field<'description', string>
        | Field<'canonical_url', string>
        | Field<'product_count', number>
        | Field<'meta_keywords', string>
        | Field<'default_sort_by', string>
        | Field<'meta_description', string>
        | Field<'landing_page', number>
        | Field<'display_mode', string>
        | Field<'is_anchor', boolean>
        | Field<'cms_block', CmsBlock>
        | Field<'home_left_block_details', CmsBlock>
        | Field<'products', CategoryProducts>
    > {
        return [
            new Field<'id', number>('id'),
            new Field<'url', string>('url'),
            new Field<'name', string>('name'),
            new Field<'image', string>('image'),
            new Field<'url_key', string>('url_key'),
            new Field<'url_path', string>('url_path'),
            new Field<'is_active', boolean>('is_active'),
            new Field<'meta_title', string>('meta_title'),
            new Field<'description', string>('description'),
            new Field<'canonical_url', string>('canonical_url'),
            new Field<'product_count', number>('product_count'),
            new Field<'meta_keywords', string>('meta_keywords'),
            new Field<'default_sort_by', string>('default_sort_by'),
            new Field<'meta_description', string>('meta_description'),
            new Field<'landing_page', number>('landing_page'),
            new Field<'display_mode', string>('display_mode'),
            new Field<'is_anchor', boolean>('is_anchor'),
            this._getCmsBlockField(),
            this._getHomeLeftCmsBlockField(),
            CategoryListQuery._ProductField()
        ];
    }

    _getCategoryProduct(): Field<'products', CategoryProducts> {
        return new Field<'products', CategoryProducts>('products')
            .addFieldList(this._getCategoryProductItems());
    }

    _getCategoryProductItems(): Field<'items', ProductInterface> {
        return new Field<'items', ProductInterface>('items')
            .addFieldList(this._ProductItemFields());
    }

    _getCategoryProductItem(): Array<
        Field<'type_id', string>
        | Field<'name', string>
        | Field<'quantity', number>
        | Field<'url_key', string>
        | Field<'image', OptimizedProductImage>
        | Field<'price_range', PriceRange>
        | Field<'small_image', OptimizedProductImage>
        | Field<'stock_status', GQLProductStockStatus>
    > {
        return [
            new Field<'name', string>('name')
        ];
    }

    _getHomeLeftCmsBlockField(): Field<'home_left_block_details', CmsBlock> {
        return new Field<'home_left_block_details', CmsBlock>('home_left_block_details')
            .addFieldList(this._getCmsBlockFields());
    }

    _getCmsBlockField(): Field<'cms_block', CmsBlock> {
        return new Field<'cms_block', CmsBlock>('cms_block')
            .addFieldList(this._getCmsBlockFields());
    }

    _getCmsBlockFields(): Array<
        Field<'content', string>
        | Field<'disabled', boolean>
        | Field<'title', string>
        | Field<'identifier', string>
    > {
        return [
            new Field<'content', string>('content'),
            new Field<'disabled', boolean>('disabled'),
            new Field<'title', string>('title'),
            new Field<'identifier', string>('identifier'),
        ];
    }

    _getCategoryArguments(): Array<[string, string, unknown]> {
        const { args = [] } = this.options;
        const argumentMap = this._getArgumentsMap();

        type ArgumentsMapKey = keyof ArgumentsMap;

        return Object.entries(args).reduce((
            acc,
            [key, arg],
        ) => {
            if (!arg) {
                return acc;
            }

            const {
                type,
                handler = (option: unknown): unknown => option,
            } = argumentMap[key as ArgumentsMapKey];

            return [
                ...acc,
                [key, type, handler(arg as never)],
            ];
        }, [] as [string, string, unknown][]);
    }

    _getArgumentsMap(): ArgumentsMap {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: {
                type: 'Int',
                handler: undefined,
            },
            pageSize: {
                type: 'Int',
                handler: (option: number) => (requireInfo ? 1 : option),
            },
            filters: {
                type: 'CategoryFilterInput',
                handler: (initialOptions = {}) => {

                    const options: CategoryAttributeFilterOptions = {
                        ...initialOptions
                    };

                    type FilterArgumentMapKey = keyof FilterArgumentMap;

                    const parsedOptions: Record<string, unknown> = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key as FilterArgumentMapKey]) {
                                return acc;
                            }

                            return {
                                ...acc,
                                ...filterArgumentMap[key as FilterArgumentMapKey](option as never),
                            };
                        },
                        {} as Record<string, unknown>,
                    );

                    return parsedOptions;
                },
            },
        };
    }

    _getFilterArgumentMap(): FilterArgumentMap {
        return {
            urlKey: (url: string) => ({ url_key: { eq: url } })
        };
    }

}

export default new HomePageQuery();
